<template>
  <div class="message-toast">
    <div :class="message.levelName()" v-for="message of messages" :key="message.id">{{ message.text }}</div>
  </div>
</template>

<script>
import store from '@/store'
import { htmlText } from '@/utils/html'

export default {
  name: 'MessageToast',
  watch: {},
  computed: {
    messages() {
      return store.getters.messages
    },
    hasMessages() {
      return store.getters.hasMessages
    },
  },
  methods: {
    htmlText(message) {
      return htmlText(message.text)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.message-toast {
  margin: 20px;
}

.message-toast > div {
  margin-bottom: 10px;
  padding: 5px 10px;
}

.message-toast > div.error {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.message-toast > div.warning {
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

.message-toast > div.success {
  background: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
</style>
