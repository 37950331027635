<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <span v-if="customLogo">
            <img
              src="@/assets/img/millibase-full.svg"
              style="width: 300px;"
            />
          </span>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">

      <ion-row v-if="customLogo" class="structure-header">
        <ion-col v-if="structureTitle" class="structure-title">
          <div v-html="structureTitle"></div>
        </ion-col>
        <ion-col v-if="structureLogo" class="structure-logo" size="3">
          <img :src="structureLogo" class="img-fluid" />
        </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col>
          <div class="millibase-logo">
            <img
              src="@/assets/img/millibase-full.svg"
              style="width: 300px;"
            />
          </div>
        </ion-col>
      </ion-row>

      <ion-row v-if="structureInfo" class="structure-info">
        <ion-col>
          <div v-html="structureInfo"></div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <france-connect-button></france-connect-button>
        </ion-col>
      </ion-row>

      <div class="login-form">
        <ion-item>
          <ion-label>Adresse email</ion-label>
          <ion-input
              v-model="form.email"
              placeholder="prenom.nom@example.fr"
              :disabled="step > 1"
          ></ion-input>
        </ion-item>

        <ion-item v-if="backends.length > 1">
          <ion-label>Structure</ion-label>
          <ion-select
            placeholder="Choisissez une base de données"
            v-model="selectedBackend"
            @ionChange="step = 3"
          >
            <ion-select-option v-for="backend of backends" :key="backend.name" :value="backend">
              {{ backend.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <new-portal-redirect
          v-if="step === 3 && redirectToNewPortal"
          :backend-url="backendUrl"
        >
        </new-portal-redirect>
        <ion-item v-if="step === 3 && !redirectToNewPortal">
          <ion-label>Mot de passe</ion-label>
          <ion-input :type="showPassword ? 'text' : 'password'" v-model="form.password"></ion-input>
        </ion-item>
        <ion-row v-if="step === 3 && !redirectToNewPortal">
          <ion-col class="text-right">
            <a href @click.prevent="showPassword = !showPassword">
              <i class="fa fa-eye" v-if="showPassword"></i>
              <i class="fa fa-eye-slash" v-else></i>
            </a>
          </ion-col>
        </ion-row>

        <message-toast></message-toast>

        <div class="button-holder hide-small" v-if="!redirectToNewPortal">
          <ion-row>
            <ion-col v-if="step>1">
              <ion-button @click="onReset()" color="dark" class="block-btn">
                Réinitialiser
              </ion-button>
            </ion-col>
            <ion-col v-if="step>1">
              <ion-button
                  @click="onForgotten()" :disabled="step < 3" color="dark" class="block-btn"
                  :class="{transparent: step < 3}"
              >
                Mot de passe oublié
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button @click="onSubmit" color="primary" :disabled="!isSubmitActive()" class="block-btn">
                <span v-if="step===3">Se connecter</span>
                <span v-else>Suivant</span>
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <div class="button-holder show-small">
          <ion-row>
            <ion-col>
              <ion-button @click="onSubmit" color="primary" :disabled="!isSubmitActive()" class="block-btn">
                <span v-if="step===3">Se connecter</span>
                <span v-else>Suivant</span>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button @click="onReset()" color="dark" class="block-btn">
                Réinitialiser
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                  @click="onForgotten()" :disabled="step < 3" color="dark" class="block-btn"
                  :class="{transparent: step < 3}"
              >
                Mot de passe oublié
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
// @ is an alias to /src

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonInput,
  IonSelect, IonSelectOption, IonRow, IonCol
} from '@ionic/vue'
import MessageToast from '@/components/MessageToast'
import NewPortalRedirect from '@/components/NewPortalRedirect.vue'
import FranceConnectButton from "@/components/Login/FranceConnectButton.vue"
import { isEmailValid } from '@/utils/check'


export default {
  name: 'login',
  components: {
    FranceConnectButton, MessageToast, NewPortalRedirect,
    IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonLabel, IonButton, IonItem, IonInput,
    IonSelect, IonSelectOption, IonRow, IonCol,
  },
  mixins: [BackendMixin],
  data() {
    return {
      selectedBackend: null,
      backends: [],
      form: {
        email: '',
        password: '',
      },
      show: true,
      step: 1,
      showPassword: false,
    }
  },
  computed: {
    structureTitle() {
      return this.getFrontendStructureTitle()
    },
    structureInfo() {
      return this.getFrontendStructureInfo()
    },
    structureLogo() {
      return this.getFrontendStructureLogo()
    },
    customLogo() {
      return this.getFrontendStructureTitle() || this.getFrontendStructureLogo()
    },
    backendUrl() {
      if (this.selectedBackend) {
        return this.selectedBackend.url
      }
      return ''
    },
    redirectToNewPortal() {
      if (this.selectedBackend) {
        return this.selectedBackend.portalV2
      }
      return false
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onLogin() {
      this.login(this.selectedBackend, this.form.email, this.form.password).then(
        () => {
          this.form.password = ''
          router.push({ name: 'home', })
        },
        (err) => {
          this.addError(err.message)
        }
      )
    },

    isSubmitActive() {
      if (this.step === 1) {
        return isEmailValid(this.form.email)
      } else if (this.step === 2) {
        return this.selectedBackend !== null
      } else {
        return this.form.password !== ''
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      if (this.step === 1) {
        // from the email get the list of emails, we can sign-in.
        this.loadBackends()
      } else if (this.step === 2) {
        if (this.selectedBackend) {
          this.step = 3
        }
      } else {
        // The use has chosen the backend -> Login
        this.onLogin()
      }
    },

    loadBackends() {
      if (this.step === 1 && this.form.email) {
        // from the email get the list of backends (instances)
        this.getBackends(this.form.email).then(
          (backends) => {
            if (backends.length === 1) {
              // If only 1 : OK : ask for password
              this.selectedBackend = backends[0]
              this.step = 3
            } else {
              // if several : let the user choose
              // if backends list is empty the API should receive an error
              this.backends = backends
              this.step = 2
            }
          },
          err => {
            this.addError(err.message)
          }
        )
      }
    },

    onForgotten() {
      const resetUrl = this.selectedBackend.url + '/users/password_reset/?famille=1'
      window.open(resetUrl, '_blank')
    },

    onReset() {
      this.form.email = ''
      this.form.password = ''
      this.backends = []
      this.selectedBackend = null
      this.step = 1
    },
  },
}
</script>

<style>
  ul.home-menu {
    list-style: none;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  ul.home-menu li {
    padding: 10px 0;
    margin: 12px 0;
    display: block;
    border-bottom: solid 1px #c8cfc8;
  }

  ul.home-menu li a{
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  ul.home-menu li a:hover {
  }

  .millibase-logo {
    text-align: center;
    font-size: 32px;
    padding: 20px 0;
  }

  .button-holder {
    margin: 20px;
  }

  .button-holder ion-col:nth-of-type(2) {
    text-align: center;
  }

  .button-holder ion-col:last-of-type {
    text-align: right;
  }

  .block-btn {
    display: block;
  }

  .text-right {
    text-align: right;
  }
  .transparent {
    opacity: 0;
  }
  @media (max-width: 533px) {
    .hide-small {
      display: none
    }
    .show-small {
      display: block;
    }
  }
  @media (min-width: 533px) {
    .hide-small {
      display: block;
    }
    .show-small {
      display: none;
    }
  }
  .structure-logo {
    text-align: center;
    vertical-align: center;
  }
  .structure-logo img {
    max-height: 80px;
    display: inline-block;
    margin: 0 auto;
    vertical-align: center;
  }
  .structure-title {
    text-align: left;
    padding: 10px;
    vertical-align: center;
    font-weight: bold;
    font-size: 15px;
  }
  .structure-header {
    padding: 5px 35px;
    background: var(--ion-color-light-tint);
    border-bottom: solid 1px var(--ion-color-dark);
  }
  .structure-info {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .login-form {
    margin: 0 20px;
    padding: 10px;
  }
</style>
